<template>
    <div>
        <!-- MENU -->
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-3 fixed-top nav-fade nav-transparent"
             :class="{ 'navbar-transparent': isTransparent }">
            <div class="container-fluid">
                <a class="navbar-brand fw-bold" href="/web/">{{ generalContents.menuContents.projectTitle }}</a>
                <button v-if="!landingPage"
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-lg-0 mb-2">
                        <li v-if="!landingPage" class="nav-item">
                            <a class="nav-link active" href="/web/">
                                <i class="fa fa-home"></i> {{ generalContents.menuContents.home }}</a>
                        </li>
                        <li v-if="homePage" class="nav-item">
                            <a class="nav-link active" href="#about">
                                <i class="fa fa-newspaper-o"></i> {{ generalContents.menuContents.about }}</a>
                        </li>
                        <li v-if="homePage" class="nav-item">
                            <a class="nav-link active" href="#form-bg">
                                <i class="fa fa-address-book-o"></i> {{ generalContents.menuContents.contactUs }}</a>
                        </li>
                        <li v-if="!landingPage" class="nav-item">
                            <a class="nav-link active" href="/">
                                <i class="fa fa-language"></i> {{ generalContents.menuContents.language }}
                            </a>
                        </li>
                    </ul>
                    <ul v-if="homePage" class="navbar-nav mb-lg-0">
                        <li class="nav-item">
                            <a :href="'/web/register/'"
                               class="btn btn-danger">{{ generalContents.menuContents.registerNow }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <router-view>

        </router-view>
    </div>
</template>

<script>

import translator from '@/views/Website/Translations/translator';

export default {
    name : 'WebLayout',
    data () {
        return {
            generalContents : translator.generalContents,
            landingPage     : false,
            homePage        : false,
            isTransparent   : true
        };
    },
    watch : {
        '$route' () {
            this.setMenuData();
        }
    },
    mounted () {
        this.setMenuData();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods : {
        setMenuData () {
            this.landingPage = this.$route.path === '/';
            this.homePage = this.$route.path === '/web/';
        },
        handleScroll () {
            this.isTransparent = window.scrollY === 0;
        }
    }
};
</script>

<style scoped>

</style>
